<template>
  <div class="create-service-branch">
    <h2 class="mb-3 text-black">Create Service Branch</h2>
    <b-container class="bv-example-row bv-example-row-flex-cols">
      <b-row class="tw-mb-2">
        <b-col>
          <div class="my-2">
            <InputText
              v-model="form.eventName"
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="text"
              isRequired
              :v="$v.form.eventName"
              :isValidate="$v.form.eventName.$error"
            />
          </div>
        </b-col>
        <b-col>
          <div class="my-2">
            <AutocompleteCustom
              placeholder="Branch"
              label="Branch"
              :optionsList="optionVenue"
              ref="autocompleteBranch"
              valueField="id"
              dataListID="id"
              showValue="provinceName"
              @handleInput="handleInput"
              v-model="form.venueId"
              id="id"
              isRequired="true"
              :v="$v.form.venueId"
              :isValidate="$v.form.venueId.$error"
              absolute
            />
          </div>
        </b-col>
      </b-row>
      <div class="my-2">
        <h4 class="text-black mb-3">Schedule</h4>
      </div>
      <b-row>
        <b-col>
          <div class="my-2">
            <div class="input-flex">
              <div class="font-weight-bold mb-2 text-black">
                From Date / Time
              </div>
            </div>
            <div class="input-container">
              <datetime
                v-model="form.startReserveDate"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="startDate"
                :min-datetime="minDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="my-2">
            <div class="input-flex">
              <div class="font-weight-bold mb-2 text-black">To Date / Time</div>
            </div>
            <div class="input-container">
              <datetime
                v-model="form.endReserveDate"
                format="dd/MM/yyyy"
                value-zone="local"
                placeholder="Please Select Date"
                ref="endDate"
                :disabled="!form.startReserveDate"
                :min-datetime="form.startReserveDate || null"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer g-text-gold"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="tw-mt-2">
        <b-col>
          <div class="my-2">
            <InputText
              v-model="form.dutation"
              textFloat="Dutation (Minute)"
              placeholder="0"
              type="number"
              name="number"
              isRequired
              :v="$v.form.dutation"
              :isValidate="$v.form.dutation.$error"
            />
          </div>
        </b-col>
        <b-col>
          <div class="my-2">
            <InputText
              v-model="form.bufferTimeAfter"
              textFloat="Buffer time after (Minute)"
              placeholder="0"
              type="number"
              name="number"
              isRequired
              :v="$v.form.bufferTimeAfter"
              :isValidate="$v.form.bufferTimeAfter.$error"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <!-- <div class="my-2">
            <InputSelect
              title="Event type"
              name="type"
              isRequired
              v-model="form.isLockProductEvent"
              v-bind:options="optionEventType"
              valueField="id"
              textField="name"
              :v="$v.form.isLockProductEvent"
              :isValidate="$v.form.isLockProductEvent.$error"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >-- Select EventType --</b-form-select-option
                >
              </template>
            </InputSelect>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
    <div class="footer-save" @click="handleSave">
      <b-button class="btn-save" :disabled="isLoading">
        Save
        <b-spinner
          class="m-0 ml-1"
          label="Spinning"
          small
          v-if="isLoading"
        ></b-spinner>
      </b-button>
    </div>
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
  </div>
</template>

<script>
import AutocompleteCustom from "../../servicebranch/detail/component/detailTab/component/AutocompleteBranch.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "CreateServiceBranchPage",
  components: { ModalAlertText, AutocompleteCustom },
  data() {
    return {
      isLoading: false,
      optionEventType: [
        { id: true, name: "Lock Product" },
        { id: false, name: "Product" },
      ],
      optionVenue: [],
      form: {
        startReserveDate: null,
        endReserveDate: null,
        isLockProductEvent: true,
      },
      // state modal
      textModal: "",
      arrStatus: [],
      filterOptionVenue: {
        keyword: null,
        provinceId: 0,
        eventGroupId: +this.$route.params.id,
      },
    };
  },
  validations() {
    return {
      form: {
        eventName: { required },
        venueId: { required },
        dutation: { required, minValue: minValue(1) },
        bufferTimeAfter: { required },
        isLockProductEvent: { required },
      },
    };
  },
  computed: {
    minDate() {
      return new Date().toISOString();
    },
  },
  mounted() {
    this.getVeunueOption();
  },
  methods: {
    async getVeunueOption() {
      const respone = await this.$services.servicebranch.getBranchList(
        this.filterOptionVenue,
        true
      );
      if (respone.result === 1) {
        this.optionVenue = respone.detail.data;
      }
    },
    async handleSave() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.isLoading = true;

      const body = {
        ...this.form,
        eventGroupId: +this.$route.params.id,
        endReserveDate:
          this.form.endReserveDate === "" ? null : this.form.endReserveDate,
        startReserveDate:
          this.form.startReserveDate === "" ? null : this.form.startReserveDate,
      };

      const respone = await this.$services.servicebranch.createServiceBranch(
        body
      );

      if (respone.result === 1) {
        this.$router.replace(
          `/servicebranch/${this.$route.params.id}/detail/${respone.detail.eventId}`
        );
      } else {
        this.textModal = respone.message;
        this.$bvModal.show("modal-alert-text");
      }
      this.isLoading = false;
    },
    handleInput(val) {
      this.filterOptionVenue.keyword = val;

      setTimeout(() => {
        this.getVeunueOption();
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.create-service-branch {
  background: white;
  padding: 15px;
  min-height: 60vh;

  position: relative;

  .text-black {
    color: black;
  }
  .input-flex {
    width: 100%;
  }
  .footer-save {
    position: absolute;
    bottom: 10px;
    right: 10px;

    .btn-save {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--secondary-color);
    }
  }
}
</style>
